input {
	outline: none;
}

input:focus {
	background-color: #17242F;
}

input[type=number] {
	color: rgba(255, 255, 255, 0.7);
	background-color: #1C2D3A;
	border-radius: 0.375rem;
	padding: 0.75rem 1rem;
	text-align: center;
	font-size: 1.125rem;
    line-height: 1.75rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}